.dropdown {
  position: relative;
  display: inline-block;
  margin-bottom:5vh;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  cursor: pointer; 
}

.dropdown:hover .dropdown-content {
  display: block;
}
